import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { ResizerOptions } from 'iframe-resizer-react';
import * as React from 'react';
import ExperlogixIframeHost from './iframeHost';

interface IProductModal {
    modalOpen: boolean;
    headerContent: string;
    iframeUrl: string;
    onModalToggle(): void;
}
export function ProductModal(props: IProductModal) {
    const className = `msc-cart__promotion`;
    const _iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();
    const heightCalculationMethod: ResizerOptions['heightCalculationMethod'] = 'lowestElement';

    return props.iframeUrl !== '' ? (
        <Modal isOpen={props.modalOpen} className={`${className}`} zIndex={1050} toggle={props.onModalToggle}>
            <ModalHeader className={`${className}__dialog__header`} toggle={props.onModalToggle}>
                <h5 id={'test'} className='msc-cart__promotion-popup_title'>
                    {props.headerContent}
                </h5>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                {props.modalOpen ? (
                    <ExperlogixIframeHost
                        experlogixUrl={props.iframeUrl}
                        iframeRef={_iframeRef}
                        heightCalculationMethod={heightCalculationMethod}
                        width='1px'
                        minWidth='100%'
                        autoResize={true}
                        scrolling={false}
                        log={false}
                        resizeFrom='child'
                        minHeight=''
                    ></ExperlogixIframeHost>
                ) : (
                    <></>
                )}
            </ModalBody>
            <ModalFooter className={`${className}__dialog__footer`} />
        </Modal>
    ) : (
        <></>
    );
}
