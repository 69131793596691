/* tslint:disable: no-duplicate-imports */
import { AddToCartBehavior, IAddToCartResources, ItemSuccessfullyAddedToCartNotification } from '@msdyn365-commerce/components';
import { getUrlSync, IImageSettings } from '@msdyn365-commerce/core';
import { NotificationsManager } from '@msdyn365-commerce-modules/notifications-core';
import { getTelemetryObject, IModuleProps, IPopupProps, Popup } from '@msdyn365-commerce-modules/utilities';

import classnames from 'classnames';
import * as React from 'react';

import { IExperlogixWebConfiguratorData } from '../../experlogix-d365commerce-core';
import { getExperlogixConfigureItemUrl, getExperlogixProductUrl } from '../../experlogix-d365commerce-core/urlUtilities';
import IframeHost from '../../experlogix-d365commerce-ux/iframeHost';
import {
    IExperlogixWebConfiguratorProps,
    productIsNotConfigurableBehavior,
    experience
} from './experlogix-web-configurator.props.autogenerated';

export interface IExperlogixWebConfiguratorViewProps extends IExperlogixWebConfiguratorProps<IExperlogixWebConfiguratorData> {
    Container: IModuleProps;
    ViewPort?: React.ReactNode;
    Alert?: React.ReactNode;
    Modal?: React.ReactNode;
    cookieConsentRequired: boolean;
    cookieConsentAccepted?: boolean;
}

interface IExperlogixWebConfiguratorState {
    showModal: boolean;
}

const defaultImageSettings: IImageSettings = {
    viewports: {
        xs: { q: 'w=240&h=240&m=6', w: 0, h: 0 },
        lg: { q: 'w=240&h=240&m=6', w: 0, h: 0 },
        xl: { q: 'w=240&h=240&m=6', w: 0, h: 0 }
    },
    lazyload: true
};

/**
 *
 * ExperlogixWebConfigurator component
 * @extends {React.Component<IExperlogixWebConfiguratorProps>}
 */
class ExperlogixWebConfigurator extends React.Component<
    IExperlogixWebConfiguratorProps<IExperlogixWebConfiguratorData>,
    IExperlogixWebConfiguratorState
> {
    private _dialogStrings: IAddToCartResources;
    private _iframeRef: React.RefObject<HTMLIFrameElement>;
    private _experlogixIntegrationEstablished = false;
    private _isMessageHandlerAttached = false;
    private _modalProps?: IPopupProps;

    constructor(props: IExperlogixWebConfiguratorProps<IExperlogixWebConfiguratorData>) {
        super(props);
        const { resources } = props;
        this.state = { showModal: false };
        this._iframeRef = React.createRef();

        // Setup Dialog strings
        this._dialogStrings = {
            goToCartText: resources.buyBoxGoToCartText,
            continueShoppingText: resources.buyBoxContinueShoppingText,
            closeNotificationLabel: resources.closeNotificationLabel,
            headerItemOneText: resources.buyBoxSingleItemText,
            headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
            headerMessageText: resources.buyBoxHeaderMessageText,
            freePriceText: resources.priceFree,
            originalPriceText: resources.originalPriceText,
            currentPriceText: resources.currentPriceText,
            addedQuantityText: resources.addedQuantityText
        };
    }

    public render(): JSX.Element | null {
        const {
            config: {
                experience: selectedExperience,
                requireCookieConsent,
                className,
                productIsNotConfigurableBehavior: notConfigurableBehavior
            },
            context,
            data,
            resources,
            telemetry
        } = this.props;

        const isConsentGiven =
            context.request &&
            context.request.cookies &&
            context.request.cookies.isConsentGiven &&
            context.request.cookies.isConsentGiven();

        const containerClassName = classnames('ms-iframe', 'exp-iframe', className);
        let url: string;

        switch (selectedExperience) {
            case experience.configure:
                url = getExperlogixConfigureItemUrl(context.app.config.experlogixSiteUrl);
                break;

            default:
                telemetry.exception(new Error(`The selected experience, ${selectedExperience}, is invalid.`));
                return null;
        }

        // Asserts the context and state-action
        if (!context || !data.experlogix.result) {
            telemetry.exception(new Error(`Module experlogix-web-configurator could not load state.`));
            return null;
        }

        const experlogixState = data.experlogix.result;

        // Asserts the assigned authorization nonce and currently selected product
        if (!experlogixState.authNonce || !experlogixState.product) {
            switch (notConfigurableBehavior) {
                case productIsNotConfigurableBehavior.error:
                    // No product is selected, or the selected product is not configurable, and
                    // an error should be produced.
                    const viewProps: IExperlogixWebConfiguratorViewProps = {
                        ...(this.props as IExperlogixWebConfiguratorProps<IExperlogixWebConfiguratorData>),
                        Container: {
                            moduleProps: this.props,
                            className: containerClassName
                        },
                        Alert: this._createAlert(resources.productIsNotConfigurableErrorMessage),
                        cookieConsentRequired: requireCookieConsent
                    };

                    return this.props.renderView(viewProps) as React.ReactElement;

                case productIsNotConfigurableBehavior.invisible:
                    // No product is selected, or the selected product is not configurable, and
                    // the component should not render.
                    return null;

                default:
                    telemetry.exception(new Error(`Module experlogix-web-configurator state is invalid.`));
                    return null;
            }
        }

        url = getExperlogixProductUrl(url, experlogixState, experlogixState.product.RecordId);

        let viewProps: IExperlogixWebConfiguratorViewProps = {
            ...(this.props as IExperlogixWebConfiguratorProps<IExperlogixWebConfiguratorData>),
            Container: {
                moduleProps: this.props,
                className: containerClassName
            },
            Modal: this.state.showModal ? this._createModal(this._modalProps) : null,
            cookieConsentRequired: requireCookieConsent
        };

        if (requireCookieConsent === true && isConsentGiven === false) {
            // If we required cookie consent, but none was given, establish an alert
            viewProps = {
                ...viewProps,
                Alert: this._createAlert(resources.cookieConsentRequiredMessage)
            };
        } else {
            // Attach a message handler, being mindful of re-entrancy in rendering
            if (!this._isMessageHandlerAttached) {
                self.addEventListener('message', this._handlePostMessage);
                this._isMessageHandlerAttached = true;
            }

            // Proceed with the generation of the Iframe
            viewProps = {
                ...viewProps,
                ViewPort: this._createIframe(url),
                cookieConsentAccepted: true
            };
        }

        return this.props.renderView(viewProps) as React.ReactElement;
    }

    public changeModalOpen = (isModalOpen: boolean): void => {
        this.setState({ showModal: isModalOpen });

        if (isModalOpen === false) {
            // Modal has been closed, so reload the current page
            self.location.reload();
        }
    };

    private _handlePostMessage = (message: MessageEvent) => {
        switch (message.data) {
            // Check for Experlogix initialization
            case 'expInit':
                // Acknowledge initialization, and setup bi-directional integration
                this._iframeRef.current?.contentWindow?.postMessage('init', '*');
                break;

            case 'initReceived':
                // Recognize Experlogix acknowlegement of bi-directional integration
                this._experlogixIntegrationEstablished = true;
                break;

            case 'expClosed':
                if (this._experlogixIntegrationEstablished) {
                    this._performPostConfigurationAction();
                }
                break;
        }
    };

    private _performPostConfigurationAction() {
        const {
            context,
            data: { cart, product, experlogix },
            friendlyName,
            id,
            telemetry,
            typeName
        } = this.props;

        const behavior = context.app?.config?.addToCartBehavior;
        const navigationUrl = getUrlSync('cart', context.actionContext);

        // Refresh the Cart
        cart.result?.refreshCart({});

        // If we're editing an existing line, or a "return to cart" behavior is selected, return to the cart...
        if (
            experlogix.result?.cartLineId ||
            behavior === undefined ||
            behavior === AddToCartBehavior.goToCart ||
            // The Experlogix interface doesn't strictly support using a mini-cart
            behavior === AddToCartBehavior.showMiniCart
        ) {
            if (navigationUrl) {
                self.location.assign(navigationUrl);
            }
        }
        // ... or, if we are to show a modal
        else if (behavior === AddToCartBehavior.showModal) {
            this._modalProps = {
                context,
                className: 'ms-buybox',
                id,
                typeName,
                // TODO: Must read from configuration
                data: { product: product?.result, price: undefined },
                dialogStrings: this._dialogStrings,
                imageSettings: defaultImageSettings,
                gridSettings: context.request.gridSettings,
                // TODO: Must read from configuration
                productQuantity: 1,
                priceComponent: undefined,
                navigationUrl: getUrlSync('cart', context.actionContext),
                modalOpen: true,
                setModalOpen: this.changeModalOpen
            };

            this.changeModalOpen(true);
        }
        // ... or, if we are to show a notification
        else if (behavior === AddToCartBehavior.showNotification) {
            const telemetryContent = getTelemetryObject(context.request.telemetryPageName!, friendlyName, telemetry);

            const notification = new ItemSuccessfullyAddedToCartNotification(
                context,
                this._dialogStrings,
                defaultImageSettings,
                context.request.gridSettings,
                // TODO: Must read from configuration
                product.result!,
                // TODO: Must read from configuration
                undefined,
                // TODO: Must read from configuration
                1,
                navigationUrl,
                telemetryContent,
                id,
                typeName
            );

            NotificationsManager.instance().addNotification(notification);
        }
    }

    private _createAlert(message: string): React.ReactNode | null {
        return (
            <p className='ms-iframe__error-message exp-iframe__error-message' role='alert' aria-live='assertive'>
                {message}
            </p>
        );
    }

    private _createIframe(url: string): React.ReactNode | null {
        return <IframeHost iframeRef={this._iframeRef} experlogixUrl={url} {...this.props.config} />;
    }

    private _createModal(popupProps?: IPopupProps) {
        return popupProps ? <Popup {...popupProps} /> : undefined;
    }
}

export default ExperlogixWebConfigurator;
